import FAQ from "../content/faq";
import ForSME from "../content/forSME";
import HeroTop from "../content/heroTop";
import HowItWorks from "../content/howItWorks";
import LocationsComponent from "../content/locations";
import OurStats from "../content/ourStats";
import TrustUs from "../content/shield";

const MainContent = () => {
  return (
    <>
      <HeroTop />
      <LocationsComponent />
      <HowItWorks />
      <TrustUs />
      <OurStats />
      <ForSME />
      <FAQ />
    </>
  );
};

export default MainContent;
