const SignUpPage = () => {
  return (
    <div>
      <iframe
        id="JotFormIFrame-83172813260553"
        title="Waybill Registration Portal"
        onLoad={() => window.parent.scrollTo(0, 0)}
        allowTransparency="true"
        allowFullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.myjotform.com/83172813260553"
        frameBorder="0"
        style={{ minWidth: "100%", height: "539px", border: "none" }}
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default SignUpPage;
