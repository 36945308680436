import { useEffect, useState } from "react";
import { faqData } from "../../data";
import { dropDown } from "../../images";
import OutlineDiv from "../outlineDiv";

const FAQ = () => {
  return (
    <div className="uk-container uk-margin-large-top">
      <h2 className="w800 uk-margin-large-bottom">
        Frequently Asked Questions
      </h2>

      {faqData.map((item, idx) => (
        <FAQItem key={idx} {...item} idx={idx} />
      ))}
    </div>
  );
};

const FAQItem = ({ title, text, idx }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (idx === 0) {
      setIsOpen(true);
    }
  }, [idx]);

  return (
    <div className="uk-margin">
      <OutlineDiv className="faq-div">
        <div
          className="uk-grid-small faq-head"
          onClick={() => setIsOpen(!isOpen)}
          data-uk-grid
        >
          <div className="uk-width-expand">
            <h4 className="w700 uk-margin-remove">{title}</h4>
          </div>
          <div className="uk-width-auto">
            <img
              src={dropDown}
              alt="Expand"
              className={`faq-img ${isOpen ? "open" : ""}`}
            />
          </div>
        </div>

        <div className={`faq-body-wrapper ${isOpen ? "open" : ""}`}>
          <div className={`faq-body`}>
            <p className="w500" style={{ fontSize: 20 }}>
              {text}
            </p>
          </div>
        </div>
      </OutlineDiv>
    </div>
  );
};

export default FAQ;
