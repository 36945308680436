export const addressData = [
  {
    title: "Lagos",
    address: "Dagunro close, off Associate Ave, Ojota, Lagos",
    contact: "0912 234 7017 | 0704 804 6484",
  },
  {
    title: "Ile-Ife",
    address: "206, Ruth n Boaz Plaza, Mayfair, Ile-Ife, Osun State.",
    contact: "0704 804 6484",
  },
  {
    title: "Ibadan",
    address: "85, Adebayo Idito Road, Sango Eleyele (The Poly Ibadan)",
    contact: "0704 804 6484",
  },
  {
    title: "Oshogbo",
    address: "Ush Pro, Alekuwodo, Oshogbo, Osun State.",
    contact: "0706 828 4982 | 0704 804 6484",
  },
];

export const howItWorksList = [
  {
    id: 1,
    text: "Check-in  parcel at any of our Experience centers.",
  },
  {
    id: 2,
    text: "Departure time is; 8:00hrs. 10:30hrs, 2:30hrs. All parcels arrive the same day.",
  },
  {
    id: 3,
    text: "Customers can pick up at our office to save cost, or pay a little extra for a doorstep delivery.",
  },
];

export const faqData = [
  {
    title: "Can you pick up my package at my location?",
    text: "We strongly advise that you bring your package yourself, or send a dispatch rider. We have dispatch partners who can work with you on pickups and delievries.",
  },
  {
    title: "Do you charge by Kg or other weighing methods?",
    text: "We like to keep it simple. We do not weigh parcels; just ₦500 flat rate per percel to a destination.",
  },
  {
    title: "Do you accept payment on package delivery?",
    text: "Unfortunately, we currently do not allow payment on delivery, we’ll make sure to inform you if we ever consider it :)",
  },
  {
    title: "Why is my NIN or ID card required during registration",
    text: "For security witnessing.",
  },
];
