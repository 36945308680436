import { Link } from "react-router-dom";
import { arrowRight, phoneHand } from "../../images";
import OutlineDiv from "../outlineDiv";

const HeroTop = () => {
  return (
    <div className="uk-container uk-margin-top uk-margin-large-bottom">
      <OutlineDiv className="hero-top-div">
        <div className="uk-width-5-6@m uk-width-1-1 uk-margin-auto padding-sm">
          <div
            className="uk-grid-match"
            style={{ minHeight: "100%" }}
            data-uk-grid
          >
            <div className="uk-width-3-5@m uk-width-1-1">
              <div
                className="text-centerM"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h1 className="w900 hero-heading">
                  Experience the fastest & cheapest way to send & recieve
                  inter-state parcels.
                </h1>
                <h4 className="w600 text-white">
                  We help Nigerian SME’s send parcels for a flat rate of{" "}
                  <span className="text-black">₦500 only</span> and get it
                  delivered <span className="text-black">the same day!</span>
                </h4>

                <div className="uk-margin-large-top">
                  <p className="w800">
                    <Link to="/signup" className="hero-getstarted">
                      Get Started <img src={arrowRight} alt="get started" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="uk-width-2-5@m uk-width-1-1">
              <div className="hero-img">
                <img src={phoneHand} alt="Phone" />
              </div>
            </div>
          </div>
        </div>
      </OutlineDiv>
    </div>
  );
};

export default HeroTop;
