import { Link } from "react-router-dom";
import { arrowGet, stall } from "../../images";

const ForSME = () => {
  return (
    <div className="forSME-div uk-background-secondary uk-margin-large-top">
      <div className="uk-container">
        <div
          className="uk-grid-row-large"
          style={{ alignItems: "center" }}
          data-uk-grid
        >
          <div className="uk-width-4-5@m uk-width-1-1">
            <h1 className="text-offwhite w900 forSME-hero">
              For SME’s, Vendors & Businesses, Let’s Onboard You!
            </h1>

            <div className="uk-margin-large-top">
              <p className="w800">
                <Link to="/signup" className="hero-getstarted text-offwhite">
                  Get in here <img src={arrowGet} alt="Get in here" />
                </Link>
              </p>
            </div>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <img src={stall} alt="stall" className="forSME-stall" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForSME;
