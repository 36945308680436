import { useState } from "react";
import { Link } from "react-router-dom";
import { dropDown, logoBlack } from "../../images";

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleMobileMenu = (e) => {
    e.preventDefault();

    setShowNav(!showNav);
  };

  return (
    <div className="uk-container">
      <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
        <div className="uk-navbar-left">
          <div className="uk-navbar-item">
            <Link to="/">
              <img src={logoBlack} alt="Instadrop" className="header-logo" />
            </Link>
          </div>
        </div>

        <div className="uk-navbar-right uk-visible@m">
          <ul className="uk-navbar-nav">
            <li>
              <Link to="/partnership">For Partners</Link>
            </li>
            <li>
              <Link to="/signup">For Business</Link>
            </li>
          </ul>
          <div className="uk-navbar-item">
            <Link to="#contact">
              <p className="uk-margin-remove">
                Contact us <img src={dropDown} alt="Contact us" />
              </p>
            </Link>
          </div>
        </div>

        <div className="uk-navbar-right uk-hidden@m">
          <ul className="uk-navbar-nav">
            <li>
              <a href="/" onClick={toggleMobileMenu}>
                <i data-uk-icon="icon: menu; ratio: 1.5"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile menu */}
      <div className={`uk-hidden@m mobileMenu ${showNav ? "open" : ""}`}>
        <ul className="uk-nav-primary uk-nav-parent-icon" data-uk-nav>
          <li className="uk-active">
            <Link to="/partnership">For Partners</Link>
          </li>
          <li>
            <Link to="/signup">For Business</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
