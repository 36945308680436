const PartnershipPage = () => {
  return (
    <div>
      <iframe
        id="JotFormIFrame-212337441899060"
        title="Instadrop for Partners"
        onLoad={() => window.parent.scrollTo(0, 0)}
        allowTransparency="true"
        allowFullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/212337441899060"
        frameBorder="0"
        style={{ minWidth: "100%", height: "539px", border: "none" }}
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default PartnershipPage;
