import { howItWorksList } from "../../data";
import { bikeman } from "../../images";
import OutlineDiv from "../outlineDiv";

const HowItWorks = () => {
  return (
    <div className="uk-container uk-margin-xlarge-top">
      <div style={{ alignItems: "center" }} data-uk-grid>
        <div className="uk-width-1-3@m uk-width-1-1">
          <img src={bikeman} alt="Bike" className="how-it-works-bike" />
        </div>
        <div className="uk-width-2-3@m uk-width-1-1">
          <div
            className="uk-grid-small"
            style={{ alignItems: "center" }}
            data-uk-grid
          >
            <div className="uk-width-auto">
              <h2 className="w700">How it works</h2>
            </div>
            <div className="uk-width-expand">
              <OutlineDiv style={{ padding: 3 }}></OutlineDiv>
            </div>
          </div>

          {howItWorksList.map((item) => (
            <WorkList key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

const WorkList = ({ id, text }) => {
  return (
    <div data-uk-grid>
      <div className="uk-width-auto">
        <div className="how-it-works-div">
          <div className="how-it-works-no">
            <h3>{id}</h3>
          </div>
        </div>
      </div>
      <div className="uk-width-expand">
        <h3 className="w600">{text}</h3>
      </div>
    </div>
  );
};

export default HowItWorks;
