import { addressData } from "../../data";
import { arrowCircleRight, cursor } from "../../images";
import OutlineDiv from "../outlineDiv";

const LocationsComponent = () => {
  return (
    <div className="uk-container">
      <div data-uk-grid>
        <div className="uk-width-1-3@m uk-width-1-1">
          <OutlineDiv
            style={{ height: 100, padding: 6, display: "inline-block" }}
          ></OutlineDiv>
          <h2 className="w700">We are live in these locations:</h2>
        </div>
        <div className="uk-width-2-3@m uk-width-1-1">
          <div
            className="uk-position-relative"
            tabIndex="-1"
            data-uk-slider="finite: true"
          >
            <ul className="uk-slider-items uk-grid-match uk-child-width-1-1">
              {addressData.map((item, idx) => (
                <li key={idx} style={{ padding: 5 }}>
                  <OutlineDiv className="uk-padding">
                    <h1 className="location-name uk-text-uppercase">
                      {item.title}
                    </h1>
                    <img
                      src={cursor}
                      alt="Location"
                      className="location-cursor"
                    />
                    <h4 className="w700 uk-width-5-6 uk-margin-small-top">
                      {item.address}
                    </h4>
                  </OutlineDiv>
                </li>
              ))}
            </ul>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover location-slider-previous-a"
              href="/"
              data-uk-slider-item="previous"
            >
              <img
                src={arrowCircleRight}
                alt="Next"
                className="location-slider-previous"
              />
            </a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover location-slider-next-a"
              href="/"
              data-uk-slider-item="next"
            >
              <img
                src={arrowCircleRight}
                alt="Next"
                className="location-slider-next"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationsComponent;
