import { handMoney, rocket, shield } from "../../images";

const TrustUs = () => {
  return (
    <div className="uk-container uk-margin-xlarge-top">
      <h1 className="uk-text-center uk-margin-xlarge-bottom w900">
        Instadrop is building the infrastructure of same day interstate doorstep
        delivery for Nigerian SMEs
      </h1>
      <div
        className="uk-text-center uk-grid-column-small uk-grid-row-large uk-child-width-1-3@m uk-child-width-1-1"
        data-uk-grid
      >
        <div>
          <div className="uk-width-5-6 uk-margin-auto">
            <img src={shield} alt="Reliable" className="trust-img" />
            <p className="w600" style={{ fontSize: 20 }}>
              We are Reliable. Be Rest assure that your packages will get to
              their destination intact.
            </p>
          </div>
        </div>
        <div>
          <div className="uk-width-5-6 uk-margin-auto">
            <img src={handMoney} alt="Reliable" className="trust-img" />
            <p className="w600" style={{ fontSize: 20 }}>
              We help you save up to 75% of waybill cost in delivering your
              percels to customers. We are unmatched.
            </p>
          </div>
        </div>
        <div>
          <div className="uk-width-5-6 uk-margin-auto">
            <img src={rocket} alt="Reliable" className="trust-img" />
            <p className="w600" style={{ fontSize: 20 }}>
              We deliver your parcel to the destination on the same day at the
              cheapest price for just N500 only
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustUs;
