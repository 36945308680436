import Layout from "./components/layout/layout";
import MainContent from "./components/layout/mainContent";
import SignUpPage from "./components/layout/signup";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style.css";
import PartnershipPage from "./components/layout/partnership";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" exact element={<MainContent />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/partnership" element={<PartnershipPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
