import { addressData } from "../../data";
import {
  facebookLink,
  instagramLink,
  telegramLink,
  twitterLink,
  whatsappLink,
} from "../../data/links";
import {
  facebook,
  instagram,
  logoWhite,
  telegram,
  twitter,
  whatsapp,
} from "../../images";

const Footer = () => {
  return (
    <div className="uk-container" style={{ paddingBottom: 100 }}>
      <footer
        id="contact"
        className="uk-background-secondary uk-border-rounded"
      >
        <div className="footer-logo">
          <img src={logoWhite} alt="Instadrop Logo" />
        </div>
        <div
          className="uk-grid-column-small uk-grid-row-large"
          style={{ alignItems: "flex-end" }}
          data-uk-grid
        >
          <div className="uk-width-1-4@m uk-width-1-1">
            <h3 className="text-offwhite w700">Get in Touch</h3>
            <div className="social-icons">
              <a href={whatsappLink}>
                <img src={whatsapp} alt="whatsapp" />
              </a>
              <a href={telegramLink}>
                <img src={telegram} alt="telegram" />
              </a>
              <a href={facebookLink}>
                <img src={facebook} alt="facebook" />
              </a>
              <a href={twitterLink}>
                <img src={twitter} alt="twitter" />
              </a>
              <a href={instagramLink}>
                <img src={instagram} alt="instagram" />
              </a>
            </div>
            <p className="uk-margin-remove text-white">InstadropHQ@gmail.com</p>
            <p className="uk-margin-remove text-white">+234 (0)704 804 6484</p>
          </div>
          <div className="uk-width-3-4@m uk-width-1-1">
            <div
              className="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-row-large"
              data-uk-grid
            >
              {addressData.map((item, idx) => (
                <Address key={idx} {...item} />
              ))}
            </div>
          </div>
        </div>
        <hr className="uk-margin-large-top uk-margin-medium-bottom" />
        <p className="uk-margin-remove text-white">
          &copy; {new Date().getFullYear()} - InstaDrop Logistics and Transport
          Limited
        </p>
      </footer>
    </div>
  );
};

const Address = ({ title, address, contact }) => {
  return (
    <div>
      <h5 className="uk-margin-small-bottom text-offwhite w800">{title}</h5>
      <p className="uk-margin-remove text-white w600 uk-text-small">
        {address}
      </p>
      <p className="uk-margin-remove text-offwhite w600 uk-text-small">
        {contact}
      </p>
    </div>
  );
};

export default Footer;
