import OutlineDiv from "../outlineDiv";

const OurStats = () => {
  return (
    <div className="uk-container uk-margin-large-top">
      <div
        className="uk-text-center uk-grid-match uk-child-width-1-3@m uk-child-width-1-1"
        data-uk-grid
      >
        <div>
          <OutlineDiv className="uk-padding-small">
            <h4 className="krona">Trips</h4>
            <h1 className="uk-margin-remove-bottom krona">50+</h1>
          </OutlineDiv>
        </div>
        <div>
          <OutlineDiv className="uk-padding-small">
            <h4 className="krona">Parcels</h4>
            <h1 className="uk-margin-remove-bottom krona">400+</h1>
          </OutlineDiv>
        </div>
        <div>
          <OutlineDiv className="uk-padding-small">
            <h4 className="krona">Satisfaction</h4>
            <h1 className="uk-margin-remove-bottom krona">99.9%</h1>
          </OutlineDiv>
        </div>
      </div>
    </div>
  );
};

export default OurStats;
